<template>
    <tr>
        <td class="text-nowrap">
            <div class="d-flex gap-2 align-items-center">
                <div v-if="item.profilePictureUrl">
                    <img :src="avatarImage" alt="Profile Picture" class="profile-avatar shadow-lg me-1" />
                </div>
                <div v-else class="default-avatar shadow-lg me-1">
                    <span class="avatar-letter">{{ avatarLetter }}</span>
                </div>
                <p class="my-auto">
                    {{ item.firstName }} {{ item.lastName }}
                </p>
            </div>
        </td>
        <td>{{ item.email }}</td>
        <td class="text-nowrap">
            <!-- <MDBTooltip v-if="item.userTypeId != 1" v-model="tooltipViewDashboard" boundary="scrollParent">
                <template #reference>
                    <MDBBtn outline="primary" rounded @click.stop="impersonateUser(item)" size="sm"
                        class="btn btn-sm me-2">
                        <vue-feather type="bar-chart-2" :stroke-width="3" size="16" />
                    </MDBBtn>
                </template>
                <template #tip> View Dashboard </template>
            </MDBTooltip> -->
            <router-link :to="{ name: 'UpdateUser', params: { id: item.id } }" custom v-slot="{ href, navigate }">
                <MDBTooltip v-model="tooltipEdit">
                    <template #reference>
                        <MDBBtn tag="a" rounded outline="primary" @click.stop="navigate" :href="href" size="sm"
                            class="me-2">
                            <vue-feather type="edit" :stroke-width="3" size="16" />
                        </MDBBtn>
                    </template>
                    <template #tip> Edit Information </template>
                </MDBTooltip>
            </router-link>
            <router-link :to="{
                name: 'AdjustmentsNew',
                params: {
                    id: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                },
            }" custom v-slot="{ href, navigate }">
                <MDBTooltip v-model="tooltipAdjustment">
                    <template #reference>
                        <MDBBtn tag="a" rounded outline="primary" @click.stop="sendParams(item, navigate)" :href="href"
                            size="sm" class="me-2">
                            <vue-feather type="sliders" :stroke-width="3" size="16" />
                        </MDBBtn>
                    </template>
                    <template #tip> View Adjustments </template>
                </MDBTooltip>
            </router-link>
            <router-link :to="{
                name: 'PartnerPercentage',
                params: {
                    id: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                },
            }" custom v-slot="{ href, navigate }">
                <MDBTooltip v-model="tooltipPartnerPercentage">
                    <template #reference>
                        <MDBBtn tag="a" rounded outline="primary" @click.stop="sendParams(item, navigate)" :href="href"
                            size="sm" class="me-2">
                            <vue-feather type="percent" :stroke-width="3" size="16" />
                        </MDBBtn>
                    </template>
                    <template #tip> Update Partner Percentage </template>
                </MDBTooltip>
            </router-link>
            <router-link :to="{
                name: 'MonthlyReport',
                params: {
                    id: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                },
            }" custom v-slot="{ href, navigate }">
                <MDBTooltip v-model="tooltipMonthlyReport">
                    <template #reference>
                        <MDBBtn tag="a" rounded outline="primary" @click.stop="sendParams(item, navigate)" :href="href"
                            size="sm" class="me-2">
                            <vue-feather type="file" :stroke-width="3" size="16" />
                        </MDBBtn>
                    </template>
                    <template #tip> View Monthly Report </template>
                </MDBTooltip>
            </router-link>
            <MDBTooltip v-model="tooltipResetPassword" v-if="item.isActive">
                <template #reference>
                    <MDBBtn rounded outline="primary" @click.stop="clickAction(item, 'resetPassword')" size="sm"
                        class="me-2">
                        <vue-feather type="unlock" :stroke-width="3" size="16" />
                    </MDBBtn>
                </template>
                <template #tip> Reset Password </template>
            </MDBTooltip>
            <MDBTooltip v-else v-model="tooltipResendActivationLink">
                <template #reference>
                    <MDBBtn rounded outline="primary" size="sm" @click.stop="resendActivationLink(item.id)">
                        <vue-feather type="send" :stroke-width="3" size="16" style="padding-right: 2px;" />
                    </MDBBtn>
                </template>
                <template #tip> Resend Activation Link </template>
            </MDBTooltip>
            <MDBTooltip v-model="tooltipDelete">
                <template #reference>
                    <MDBBtn rounded outline="primary" @click.stop="clickAction(item, 'delete')" size="sm">
                        <vue-feather type="trash-2" :stroke-width="3" size="16" />
                    </MDBBtn>
                </template>
                <template #tip> Delete Account </template>
            </MDBTooltip>
        </td>
        <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteModalLabel" v-model="deleteModal" class="ps-0">
            <MDBModalHeader>
                <MDBModalTitle id="deleteModalLabel" class="fw-bold"> Delete Account </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>Are you sure you want to delete this account?</MDBModalBody>
            <MDBModalFooter class="gap-2 py-3">
                <MDBBtn class="fw-bold" @click="deleteModal = false" size="sm"> Close </MDBBtn>
                <MDBBtn class="fw-bold" color="danger" @click="deleteUser(modalItem)" size="sm">
                    Confirm
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <MDBModal id="resetPasswordModal" tabindex="1" labelledby="resetPasswordModalLabel" v-model="resetPasswordModal"
            class="ps-0">
            <MDBModalHeader>
                <MDBModalTitle class="fw-bold" id="resetPasswordModalLabel">
                    Reset Password
                </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                Are you sure you want to reset this account's password?
            </MDBModalBody>
            <MDBModalFooter class="py-3 gap-2">
                <MDBBtn class="fw-bold" size="sm" @click="resetPasswordModal = false"> Close </MDBBtn>
                <MDBBtn class="fw-bold" color="primary" size="sm" @click="resetPassword(modalItem)">
                    Confirm
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    </tr>
</template>

<script setup>
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBTooltip } from "mdb-vue-ui-kit";
import { ref, defineProps, defineEmits, computed, watch } from "vue";
import { useToastStore } from "@/store/toastStore";
import axios from "axios";
import { useSendParamsDetails } from "@/store/sendParamsDetails";
// import { useImpersonateStore } from "@/store/impersonateStore";
// import { storeToRefs } from "pinia";
// import { useRouter } from "vue-router";
import VueFeather from 'vue-feather';

const props = defineProps({
    item: Object,
});

const emit = defineEmits(["onAction"]);
const toastStore = useToastStore();
const modalItem = ref("");
const deleteModal = ref(false);
const resetPasswordModal = ref(false);
const tooltipEdit = ref(false);
const tooltipAdjustment = ref(false);
const tooltipPartnerPercentage = ref(false);
const tooltipMonthlyReport = ref(false);
const tooltipResetPassword = ref(false);
const tooltipDelete = ref(false);
// const tooltipViewDashboard = ref(false);
const tooltipResendActivationLink = ref(false);

const sendParamsDetails = useSendParamsDetails();
const sendParams = (item, navigate) => {
    sendParamsDetails.userDetail = {
        firstName: item.firstName,
        lastName: item.lastName,
    };
    navigate();
};

const clickAction = (item, type) => {
    modalItem.value = item;
    if (type === "delete") {
        deleteModal.value = true;
    } else if (type === "resetPassword") {
        resetPasswordModal.value = true;
    }
};

const deleteUser = (data) => {
    const toastSuccessDelete = {
        ...toastStore.toastStatus.success,
        ...{
            message: "Successfully deleted!",
        },
    };
    axios
        .patch("api/users/" + data.id + "/delete")
        .then(function (response) {
            if (response.status == 200) {
                emit("onAction");
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastSuccessDelete,
                };
                deleteModal.value = false;
            }
        })
        .catch(function () {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastStore.toastStatus.fail,
            };
        });
};

const resetPassword = (data) => {
    let formData = new FormData();
    formData.append("email", data.email);
    formData.append("userId", data.id);
    axios
        .post("api/users/resetuser", formData)
        .then(function (response) {
            if (response.status == 200) {
                emit("onAction");
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastStore.toastStatus.success,
                };
                resetPasswordModal.value = false;
            }
        })
        .catch(function () {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastStore.toastStatus.fail,
            };
        });
};

// const impersonateStore = useImpersonateStore();
// const { isImpersonating, userImpersonateDetail } =
//     storeToRefs(impersonateStore);
// const router = useRouter();

// const impersonateUser = (userDetail) => {
//     userImpersonateDetail.value = userDetail;
//     isImpersonating.value = true;
//     router.push("/dashboard");
// };

const avatarLetter = computed(() => {
    return props.item?.firstName?.charAt(0).toUpperCase() || '';
});

const avatarImage = ref(props.item?.profilePictureUrl);
watch(() => props.item?.profilePictureUrl, (newUrl) => {
    avatarImage.value = `${newUrl}&t=${Date.now()}`;
});

const resendActivationLink = (userId) => {
    axios
        .patch(`api/users/${userId}/activation`)
        .then((response) => {
            if (response.status == 200) {
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastStore.toastStatus.success,
                    message: "Activation link sent successfully!",
                };
            }
        })
        .catch(() => {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastStore.toastStatus.fail,
                message: "Failed to resend activation link.",
            };
        });
};
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    /* width: 30px;
    height: 30px;
    display: grid;
    place-content: center; */
    vertical-align: middle;
}

.btn-outline-primary i {
    vertical-align: top;
}

.default-avatar {
    width: 50px;
    height: 50px;
    background-color: var(--primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
    font-weight: bold;
}

.profile-avatar {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-letter {
    text-transform: uppercase;
}
</style>

<style>
.tooltip-inner {
    font-family: "Poppins", sans-serif !important;
    background-color: var(--primary);
    font-size: 12px;
    padding: 3px 12px;
}

.badge-dot {
    background-color: var(--primary);
}
</style>
